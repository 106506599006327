import * as React from "react"
import { Link } from "gatsby"
import { Heading, Text } from "@chakra-ui/react"

import ResponsiveBlock from "~components/shared/responsive-block"

const codeStyles = {
    color: "#8A6534",
    padding: 4,
    backgroundColor: "#FFF4DB",
    fontSize: "1.25rem",
    borderRadius: 4,
}

// markup
const NotFoundPage = () => {
    return (
        <ResponsiveBlock>
            <title>Not found</title>
            <Heading size="sm">Page not found</Heading>
            <Text>
                Sorry{" "}
                <span role="img" aria-label="Pensive emoji">
                    😔
                </span>{" "}
                we couldn’t find what you were looking for.
                <br />
                {process.env.NODE_ENV === "development" ? (
                    <>
                        <br />
                        Try creating a page in{" "}
                        <code style={codeStyles}>src/pages/</code>.
                        <br />
                    </>
                ) : null}
                <br />
                <Link to="/">Click Here to Go Home</Link>.
            </Text>
        </ResponsiveBlock>
    )
}

export default NotFoundPage
